<template>
    <div class="inline-block">
        <a href="https://www.kiyoh.com/reviews/1043691/doorax" class="text-gray-800 hover:text-gray-600 flex items-center" target="_blank">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="57pt"
                height="40pt"
                viewBox="0 0 57 40"
                version="1.1"
                class="w-10 inline-block"
            >
                <g id="surface1">
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(93.72549%, 84.313725%, 5.098039%); fill-opacity: 1"
                        d="M 13.589844 1.171875 C 18.371094 -0.519531 23.804688 -0.203125 28.347656 2.070312 C 29.339844 2.566406 30.289062 3.140625 31.179688 3.800781 C 28.960938 4.925781 26.941406 6.445312 25.199219 8.234375 C 22.273438 6.730469 18.738281 6.507812 15.65625 7.652344 C 13.179688 8.546875 11.015625 10.292969 9.582031 12.53125 C 8.644531 13.972656 8.019531 15.621094 7.753906 17.324219 C 5.503906 16.894531 3.246094 16.5 1 16.050781 C 1.660156 12.359375 3.417969 8.886719 5.964844 6.175781 C 8.066406 3.921875 10.707031 2.195312 13.589844 1.171875 Z M 13.589844 1.171875 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(93.72549%, 84.313725%, 5.098039%); fill-opacity: 1"
                        d="M 28.515625 10.710938 C 29.777344 8.730469 31.378906 6.980469 33.203125 5.523438 C 35.972656 8.195312 37.917969 11.734375 38.707031 15.527344 C 36.375 16.023438 34.046875 16.550781 31.710938 17.046875 C 31.296875 14.671875 30.148438 12.449219 28.515625 10.710938 Z M 28.515625 10.710938 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(40.392157%, 69.019608%, 23.921569%); fill-opacity: 1"
                        d="M 31.179688 3.800781 C 33.863281 2.441406 36.839844 1.644531 39.84375 1.589844 C 37.5 2.652344 35.222656 3.90625 33.203125 5.523438 C 31.378906 6.980469 29.777344 8.730469 28.515625 10.710938 C 26.644531 13.59375 25.476562 16.878906 24.558594 20.183594 C 24.003906 22.207031 23.546875 24.253906 23.128906 26.3125 C 21.488281 26.300781 19.847656 26.300781 18.207031 26.3125 C 15.386719 21.902344 12.570312 17.496094 9.757812 13.082031 C 11.757812 13.0625 13.761719 13.078125 15.765625 13.074219 C 16.921875 14.835938 18.085938 16.597656 19.230469 18.367188 C 20.402344 14.574219 22.453125 11.0625 25.199219 8.234375 C 26.941406 6.445312 28.960938 4.925781 31.179688 3.800781 Z M 31.179688 3.800781 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(79.215686%, 81.960784%, 16.078431%); fill-opacity: 1"
                        d="M 31.710938 17.046875 C 34.046875 16.550781 36.375 16.023438 38.707031 15.527344 C 39.597656 19.730469 39.082031 24.222656 37.257812 28.097656 C 35.675781 31.503906 33.132812 34.453125 29.984375 36.445312 C 28.667969 34.246094 27.328125 32.0625 26.03125 29.851562 C 27.171875 29.15625 28.199219 28.269531 29.050781 27.234375 C 31.378906 24.46875 32.386719 20.621094 31.710938 17.046875 Z M 31.710938 17.046875 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(89.411765%, 57.254902%, 9.803922%); fill-opacity: 1"
                        d="M 1 16.050781 C 3.246094 16.5 5.503906 16.894531 7.753906 17.324219 C 7.253906 20.351562 7.957031 23.550781 9.628906 26.097656 C 7.605469 27.378906 5.570312 28.644531 3.554688 29.9375 C 1.027344 25.847656 0.125 20.785156 1 16.050781 Z M 1 16.050781 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(81.960784%, 21.960784%, 5.490196%); fill-opacity: 1"
                        d="M 3.554688 29.9375 C 5.570312 28.644531 7.605469 27.378906 9.628906 26.097656 C 10.816406 27.925781 12.492188 29.417969 14.429688 30.375 C 12.941406 33.394531 11.398438 36.382812 9.894531 39.394531 C 6.996094 38.777344 4.175781 37.765625 1.570312 36.332031 C 1.066406 36.050781 0.566406 35.757812 0.0976562 35.421875 C 2.339844 35.039062 4.570312 34.566406 6.742188 33.875 C 5.507812 32.722656 4.449219 31.375 3.554688 29.9375 Z M 3.554688 29.9375 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(58.039216%, 74.509804%, 10.980392%); fill-opacity: 1"
                        d="M 19.882812 31.628906 C 22.039062 31.601562 24.1875 30.996094 26.03125 29.851562 C 27.328125 32.0625 28.667969 34.246094 29.984375 36.445312 C 28.613281 37.328125 27.125 38.015625 25.578125 38.507812 C 23.730469 39.136719 21.816406 39.558594 19.886719 39.816406 C 19.886719 37.085938 19.894531 34.359375 19.882812 31.628906 Z M 19.882812 31.628906 "
                    />
                    <path
                        style="stroke: none; fill-rule: nonzero; fill: rgb(87.058824%, 41.176471%, 14.901961%); fill-opacity: 1"
                        d="M 14.429688 30.375 C 16.117188 31.222656 18.003906 31.644531 19.882812 31.628906 C 19.894531 34.359375 19.886719 37.085938 19.886719 39.816406 C 16.5625 40.230469 13.171875 40.09375 9.894531 39.394531 C 11.398438 36.382812 12.941406 33.394531 14.429688 30.375 Z M 14.429688 30.375 "
                    />
                </g>
            </svg>

            <div class="block">
                <div class="text-xs text-dgreen2 inline-block">
                    <fa :icon="['fas', 'star']" class="h-3 inline-block" />
                    <fa :icon="['fas', 'star']" class="h-3 inline-block" />
                    <fa :icon="['fas', 'star']" class="h-3 inline-block" />
                    <fa :icon="['fas', 'star']" class="h-3 inline-block" />
                    <fa :icon="['fas', 'star-half']" class="h-3 inline-block" />
                </div>
                <span class="text-md font-bold ml-1 text-dblue"> {{ $page.props.kiyoh.score }} </span>
                <span class="w-full block text-gray-600 text-xs text-right">{{ $page.props.kiyoh.reviews }} beoordelingen</span>
            </div>
        </a>
    </div>
</template>
