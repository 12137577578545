<template>
  <vue-countdown :time="time" :interval="100" v-slot="{ days, hours, minutes, seconds, milliseconds }">Actie loopt nog <span v-if="days"><strong class="text-xl">{{ days }}</strong> {{ days > 1 ? 'dagen' : 'dag' }} • </span><strong class="text-xl">{{ hours }}</strong> uur • <strong class="text-xl">{{ minutes }}</strong> minuten • <strong class="text-xl">{{ seconds }} </strong> seconden
  </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
export default {

  components:{
    VueCountdown
  },

  data() {
    const now = new Date();
    const End = new Date(2023, 10, 28);

    return {
      time: End - now,
    };
  },
};
</script>