<template>
    <div class="flex items-center px-2 lg:justify-left">
        <div class="container relative mx-auto flex flex-wrap usps text-sm md:pl-auto space-x-4">
            <div class="md:w-auto md:flex-auto">
                <a target="_blank" class="hover:text-gray-800" :href="url('klantenservice', 'levertijd-verzendkosten')">
                    <span class="relative w-8 h-4 inline-block">
                        <fa
                            :icon="['fas', 'shield-blank']"
                            class="text-dgreen/10 mr-2 text-xl left-0 top-0 w-5 inline-block absolute"
                        />
                        <fa
                            :icon="['fal', 'check']"
                            class="text-dgreen mr-2 text-lg left-1 top-0 inline-block absolute"
                        />
                    </span>
                    Voor
                    <strong>23:59</strong> besteld, morgen <strong class="lg:hidden">gratis</strong> in huis<span
                        class=""
                        >&#x2a;</span
                    >
                </a>
            </div>

            <div class="lg:block flex-auto hidden items-center">
                <span class="relative w-8 h-4 inline-block">
                    <fa
                        :icon="['fas', 'shield-blank']"
                        class="text-dgreen/10 mr-2 text-xl left-0 top-0 w-5 inline-block absolute"
                    />
                    <fa :icon="['fal', 'check']" class="text-dgreen mr-2 text-lg left-1 top-0 inline-block absolute" />
                </span>

                <strong>Gratis</strong> verzending
            </div>

            <div class="xl:block flex-auto hidden items-center">
                <a target="_blank" class="hover:text-gray-800" :href="url('klantenservice', 'retourneren')">
                    <span class="relative w-8 h-4 inline-block">
                        <fa
                            :icon="['fas', 'shield-blank']"
                            class="text-dgreen/10 mr-2 text-xl left-0 top-0 w-5 inline-block absolute"
                        />
                        <fa
                            :icon="['fal', 'check']"
                            class="text-dgreen mr-2 text-lg left-1 top-0 inline-block absolute"
                        />
                    </span>

                    <strong>30 dagen</strong> bedenktijd<span class="">&#x2a;</span>
                </a>
            </div>

            <div class="2xl:block flex-auto hidden">
                <!-- <fa :icon="['fal', 'check']" class="text-dgreen mr-2 w-3 inline-block" />  -->
                <a target="_blank" class="hover:text-gray-800" :href="url('advies')" title="Deskundig advies">
                    <span class="relative w-8 h-4 inline-block">
                        <fa
                        :icon="['fas', 'shield-blank']"
                        class="text-dgreen/10 mr-2 text-xl left-0 top-0 w-5 inline-block absolute"
                        />
                        <fa :icon="['fal', 'check']" class="text-dgreen mr-2 text-lg left-1 top-0 inline-block absolute" />
                    </span>
                    
                    <strong>Deskundig</strong> advies
                </a>
            </div>
        </div>
    </div>
</template>
